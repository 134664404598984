@import url('https://fonts.googleapis.com/css?family=Sofia+Sans:regular,bold,italic&subset=latin,latin-ext');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none; /* Standard syntax */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
}

@font-face {
    font-family: "Azonix";
    src: local("Azonix"),
    url("../fonts/Azonix.otf") format("truetype");
    font-weight: auto;
}


.font-face-azonix {
    font-family: "Azonix", serif;
}

.font-face-sofia_sans {
    font-family: "Sofia Sans", serif;
}

.basic_vh {
    width: 100vh;
    height: 100vh;
}

.App {
    text-align: center;
}

.App-logo {
    height: 100%;
    max-height: 40vh;
    pointer-events: none;
    margin: 4vh;
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}